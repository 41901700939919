import React, { useContext, useEffect } from "react";
import './Evenements.scss';
import { useQuery } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import Conf from 'utils/Conf';
import CartoucheImageRond from 'components/Misc/CartoucheImageRond';
import SlugLink from 'components/Utils/SlugLink';
import Anchor from 'components/Misc/Anchor';
import Queries from 'utils/Queries';
import Utils from 'utils/Utils';
import { SettingsContext } from 'Providers/SettingsProvider';

function Evenements({accueil=false}) {
  const { refetch,networkStatus,data } = useQuery(Queries.evenements);
  const { updateLoadState,fetchedList } = useContext(SettingsContext);
  const uuid='evenements';
  useEffect(()=>updateLoadState(uuid,networkStatus),[networkStatus,updateLoadState,uuid]);
  useEffect(()=>{
    if(fetchedList.indexOf(uuid)===-1){
      console.log('fetch',uuid);
      refetch();
    }
  },[refetch,fetchedList,uuid]);
  const evts=data ? data.interface[0] : null;
  const rubriques=data ? data.rubriques : [];
  const rub=data ? data.rub[0] : {};
  return <div>
      {rub.slugs && <Anchor slug={rub.slugs[0].slug} rubId={3} className="anchor-evts"/>}
      {accueil && <div className="spacer"/>}
      <div className="evenements">
      <Grid container justifyContent='center'>
          <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
              {evts && <>
                  <h3>{evts.value}</h3>
                  <div className="evenements-desc">{Utils.parseWithLinks(evts.texte)}</div>
              </>}
          </Grid>
      </Grid>
      <Grid container justifyContent="center">
          {rubriques.map((rub,i)=><Grid item xs={12} sm={6} lg={4} key={rub.id}>
              <SlugLink item={rub}>
              <div className="evenements-fest">
                  <div className="evenements-fest-visuel">
                      <CartoucheImageRond url={rub.detail ? `${Conf.apiUrl}/assets/${rub.detail.id}?key=medium` : null } side={i%2===0 ? 'right' : 'left'}/>
                  </div>
                  <h3>{rub.titre}</h3>
                  <div className="evenements-fest-pitch">{Utils.parseWithLinks(rub.pitch)}</div>
                  <div className="evenements-fest-desc">{Utils.parseWithLinks(rub.descriptif)}</div>
              </div>
              </SlugLink>
          </Grid>)}
      </Grid>
  </div>
  </div>;
}

export default Evenements;
